<template>
  <div class="home">
    <div class="login">
      <div class="flex">
        <div class="loginBg"></div>
        <div class="loginBox">
          <h1>
            <router-link to="/">
              <img src="@/assets/images/logo.png" alt="" />
            </router-link>
          </h1>
          <h2>로그인</h2>
          <div class="form">
            <p>전화번호(아이디)</p>
            <input type="text" v-model="mobileNo" />
            <p>비밀번호</p>
            <div class="password">
              <input :type="type" v-model="password" @keyup.enter="submit" />
            </div>

            <button class="point large" @click="submit">로그인</button>
            <div v-if="error" class="error">가입하지 않은 이메일 혹은 잘못된 비밀번호입니다.</div>
          </div>
        </div>
      </div>
    </div>
    <p class="copy">Copyright&copy; GAP Note All Rights Resrved.</p>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { login } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      pwOn: false,
      type: "password",
      mobileNo: "",
      password: "",
      error: false,
    };
  },
  methods: {
    handlePasswordOnOff() {
      this.pwOn == true ? (this.type = "password") : (this.type = "text");
      this.pwOn = !this.pwOn;
    },
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    submit() {
      if (this.mobileNo == "") {
        this.openAlert("전화번호를 입력해 주세요.");
        return;
      } else if (this.password == "") {
        this.openAlert("비밀번호를 입력해 주세요.");
        return;
      }
      let data = {
        mobileNo: this.mobileNo,
        password: this.password,
      };
      login(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("LOGIN", res.data.accesstoken);
          document.location.href = "/admin/dashboard";
        } else {
          this.openAlert(res.data.message);
          return;
        }
      });
    },
  },
};
</script>
